import React, { createRef } from 'react'
import { Paper } from '@mui/material'
import { Line } from 'react-chartjs-2'
import { Chart } from 'chart.js'
import ChartDrag from 'chartjs-plugin-dragdata'
import DataManager, { DataPoint } from '../DataManager'
import Settings from './Settings'

Chart.register(ChartDrag)
let graph: Graphic

interface GraphicProps {
	dataManager: DataManager,
	settings: Settings
}

type Change = {
	datasetIndex: number,
	indexes: number[],
	values: Map<number, number>
}

export default class Graphic extends React.Component<GraphicProps> {
	public data = {
		labels: [ 0 ],	
		datasets: [
			{
				data: [ 0 ],
				fill: false,
				pointHoverRadius: 10,
				hitRadius: 20,
				borderColor: 'rgb(75, 192, 192)',
				tension: 0.4
			}
		]
	}

	private options = {
		onHover: (e: any) => {
			const point = e.chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, false)
		
			if (point.length) {
				e.native.target.style.cursor = 'grab'
			} else {
				e.native.target.style.cursor = 'default'
			}
		},
	
		plugins: {
			dragData: {
				round: 1,
				showTooltip: true,

				onDragStart: (e: any, datasetIndex: number, index: number, value: number) => {
					this.initialValues = new Map()

					let effect = this.props.settings.state.relationship
					let w = graph.data.datasets[datasetIndex]

					for (let i = -effect; i <= effect; i++) {
						if (w.data[index + i] != null) {
							this.initialValues.set(index + i, w.data[index + i])
						}
					}
				},

				onDrag: (e: any, datasetIndex: number, index: number, value: number) => {              
					e.target.style.cursor = 'grabbing'
	
					let effect = this.props.settings.state.relationship
					let w = graph.data.datasets[datasetIndex]
					let modifier = this.props.settings.state.coefficient
					let v = (value - graph.data.datasets[datasetIndex].data[index])
	
					for (let i = -effect; i <= effect; i++) {
						if (i == 0) {
							continue
						}
	
						if (w.data[index + i] != null) {
							w.data[index + i] += Math.pow(modifier, Math.abs(i)) * v
						}
					}
				},
	
				onDragEnd: (e: any, datasetIndex: number, index: number, value: number) => {
					if (this.changes.length > 100) {
						this.changes.splice(0, 1)
					}

					let indexes = []

					let effect = this.props.settings.state.relationship
					let w = graph.data.datasets[datasetIndex]

					for (let i = -effect; i <= effect; i++) {
						if (w.data[index + i] != null) {
							indexes.push(index + i)
						}
					}

					this.changes.push({
						datasetIndex: datasetIndex,
						indexes: indexes,
						values: this.initialValues
					})

					e.target.style.cursor = 'default' 
					graph.applyNewData()
				},
			},
				
			legend: {
				display: false
			},
		},
	
		scales: {
			y: {
				max: 10,
				min: 0,

				ticks: {
					stepSize: 1
				}
			},

			x: {
				max: 10,
				min: 0
			}
		},

		animation: {
			duration: 0
		},
	
		maintainAspectRatio: false
	}

	private reference = React.createRef<Chart<"line", number[], number>>()
	private initialValues: Map<number, number> = new Map()
	private changes: Change[] = []

	constructor(props: GraphicProps) {
		super(props)
		graph = this
	}

	componentDidMount() {
		document.addEventListener('keydown', (event) => {
			if (event.ctrlKey && (event.key === 'z' || event.which == 90 || event.keyCode == 90)) {
				this.undo()
			}
		})
	}

	undo() {
		if (this.changes.length == 0) {
			return
		}

		let change = this.changes.splice(this.changes.length - 1, 1)[0]

		if (this.data.datasets[change.datasetIndex]) {
			for (let i in change.indexes) {
				let v = change.values.get(change.indexes[i])

				if (v != undefined) {
					this.data.datasets[change.datasetIndex].data[change.indexes[i]] = v
				}
			}

			this.update()
		}
	}

	update() {
		if (this.reference.current != null) {
			this.reference.current.update()
		}
	}

	render() {
		const dataset = this.data.datasets[0]
		let labels: number[] = []
		let data: number[] = []

		let currentData = this.props.dataManager.getData()
		const min = this.props.dataManager.getMinPoint()
		const max = this.props.dataManager.getMaxPoint()

		for (let i = 1; i < currentData.length - 1; i++) {
			labels.push(i - 1 + min.x)
			data.push(currentData[i].y)
		}

		this.data.labels = labels
		dataset.data = data
		
		this.update()

		this.options.scales.y.max = max.y
		this.options.scales.y.min = min.y

		this.options.scales.x.max = max.x
		this.options.scales.x.min = min.x

		this.options.scales.y.ticks.stepSize = this.props.settings.getStepSize()

		return (
			<Paper elevation={1} style={{ padding: 10, height: '100%' }}>
				<Line redraw={true} data={this.data} options={this.options} style={{ height: '317px' }} ref={this.reference} />
			</Paper>
		)
	}

	applyNewData() {
		const dataset = this.data.datasets[0]
		const min = this.props.dataManager.getMinPoint()
		const max = this.props.dataManager.getMaxPoint()

		let data: DataPoint[] = []
		let i = 0
		
		data.push(min)

		for (let point of dataset.data) {
			data.push({ x: (i++) + min.x, y: point })
		}

		data.push(max)
		this.props.dataManager.importData(data)
	}
}	