import React from 'react'
import { Button } from '@mui/material'
import ReactDOM from 'react-dom'

interface FunctionDisplayProps {
	function: (x: number) => number
}

export default class FunctionDisplay extends React.Component<FunctionDisplayProps, {}> {
	private canvas: React.RefObject<HTMLCanvasElement>

	constructor(props: FunctionDisplayProps) {
		super(props)
		this.canvas = React.createRef()
	}

	componentDidMount() {
		const canvas = this.canvas.current
		
		if (canvas == null) {
			return console.error('Canvas ref is null')
		}
		
		let ctx = canvas.getContext('2d')
		
		if (ctx == null) {
			return console.error('Canvas context is null')
		}

		const width = canvas.width
		const height = canvas.height

		const fn = this.props.function

		ctx.lineWidth = 5

		for (let x = 0; x < width - 1; x++) {
			ctx.moveTo(x, height - fn(x / width) * height)
			ctx.lineTo(x + 1, height - fn((x + 1) / width) * height)

			ctx.stroke()
		}

	}

	render() {
		return (
			<canvas 
				ref={this.canvas}
				style={{
					width: '100%',
					height: '100%',
					borderRadius: 5
				}}  
			></canvas>
		)
	}
}	