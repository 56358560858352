import React from 'react'
import { FormControl, FormControlLabel, RadioGroup, Radio, Paper } from '@mui/material'
import FunctionDisplay from './FunctionDisplay'

const functions = {
	ease_in: (x: number) => x * x,
	ease_out: (x: number) => 1 - (1 - x) * (1 - x),
	ease_in_out: (x: number) => x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2
}

interface FunctionPickerProps {
	onClick: (fn: (x: number) => number) => void
}
export default class FunctionPicker extends React.Component<FunctionPickerProps> {
	constructor(props: FunctionPickerProps) {
		super(props)
	}

	handleClick(id: 'ease_in' | 'ease_out' | 'ease_in_out') {
		this.props.onClick(functions[id])
	}

	renderIcon(id: 'ease_in' | 'ease_out' | 'ease_in_out') {
		return <div style={{
			width: 50,
			height: 50,
			backgroundColor: '#eee',
			borderRadius: 5,
			borderBottom: '2px solid #ddd',
			padding: '5px',
			boxSizing: 'border-box'
		}}>
			<FunctionDisplay function={functions[id]} />
		</div>
	}

	render() {
		return (
			<Paper elevation={1} style={{ padding: 10, height: '100%' }}>
				<FormControl component="fieldset" style={{ display: 'block' }}>
					<RadioGroup name="row-radio-buttons-group">
						<FormControlLabel style={{ margin: '20px auto' }} value="ease_in" control={<Radio style={{ display: 'none' }} onClick={this.handleClick.bind(this, 'ease_in')} />} label={this.renderIcon('ease_in')} />
						<FormControlLabel style={{ margin: '0px auto' }} value="ease_out" control={<Radio style={{ display: 'none' }} onClick={this.handleClick.bind(this, 'ease_out')} />} label={this.renderIcon('ease_out')} />
						<FormControlLabel style={{ margin: '20px auto', marginBottom: '16px' }} value="ease_in_out" control={<Radio style={{ display: 'none' }} onClick={this.handleClick.bind(this, 'ease_in_out')} />} label={this.renderIcon('ease_in_out')} />
					</RadioGroup>
				</FormControl>
			</Paper>
		)
	}
}	