import React from 'react'
import Settings from './component/Settings'

import { Paper } from '@mui/material'
import Graphic from './component/Graphic'
import FunctionPicker from './component/FunctionPicker'
import DataManager from './DataManager'
import type { DataPoint } from './DataManager'
import InputDataDisplay from './component/InputDataDisplay'

interface MainStateProps {
  
}

export default class MainState extends React.Component {
	private dataManager: DataManager
	private settings = React.createRef<Settings>()

	public data: DataPoint[]

  constructor(props: MainStateProps) {
    super(props)

		this.data = []
		this.dataManager = new DataManager(this)
  }

	componentDidMount() {
		this.forceUpdate()
	}

	render() {
		return (
			<table style={{ width: '100%', height: '100%' }}>
				<tr>
					<Settings dataManager={this.dataManager} ref={this.settings} onStepChange={this.forceUpdate.bind(this)} /> {/* Calls ::render() on dataManager, renders import & export buttons */}
				</tr>
				<tr style={{ height: '100%' }}>
					{ this.renderGraph() }
				</tr>
			</table>							
		)
	}

	renderGraph() {
		const settings = this.settings.current

		if (settings == null) {
			return <div>Null settings!</div>
		}

		return <>
			<td>
				<div className="td" style={{ height: '100%' }}>
					<Graphic dataManager={this.dataManager} settings={settings} />
				</div>
			</td>
			<td style={{ height: '100%' }}>
				<div className="td">
					<InputDataDisplay dataManager={this.dataManager} stepSize={settings.state.stepSize} onChange={settings.handleStepSizeChange.bind(settings)} />
				</div>
			</td>
		</>
	}
}	