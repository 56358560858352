import React from 'react'
import { TextField } from '@mui/material'

interface KineticRelationshipProps {
	onChange: (value: number) => void,
	value: number
}

export default class KineticRelationship extends React.Component<KineticRelationshipProps> {
	constructor(props: KineticRelationshipProps) {
		super(props)
	}

	handleChange(e: any) {
		this.props.onChange(Math.max(0, e.target.value))
	}

	render() {
		return (
      <TextField size="small" label="Кинетическая зависимость" type="number" value={this.props.value} onChange={this.handleChange.bind(this)} />
    )
	}
}	