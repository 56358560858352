import MainState from './MainState'
import ExportButton from './component/ExportButton'
import ImportButton from './component/ImportButton'

import { Paper } from '@mui/material'

type DataPoint = {
	x: number,
	y: number
}

export default class DataManager {
	private appState: MainState

	constructor(state: MainState) {
		this.appState = state
		this.importRandomData()
	}

	importRandomData() {
		let data: DataPoint[] = []
		data.push({ x: 0, y: -11 })

		for (let i = 0; i < 10; i++) {
			data.push({ x: i, y: i * 0.5 })
		}

		data.push({ x: 10, y: 11})
		this.importData(data, true)
	}

	importData(data: DataPoint[], reset: boolean = false) {
		// For some reason .setState() did not work, so we have this lil workaround
		this.appState.data = data

		if (reset) {
			this.appState.forceUpdate()
		}
	}

	importFunction(fn: (x: number) => number) {
		let data: DataPoint[] = []
		
		const min = this.getMinPoint()
		const max = this.getMaxPoint()
		const count = this.getData().length - 2

		const xScale = 1 / (count - 1)
		const yScale = max.y - min.y

		data.push(min)

		for (let i = 0; i < count; i++) {
			const x = i + min.x

			data.push({ x: x, y: min.y + fn(i * xScale) * yScale })
		}

		data.push(max)
		this.importData(data, true)
	}

	exportData() {
		let output: string[] = []

		for (let point of this.appState.data) {
			output.push(`${point.x},${point.y}`)
		}

		return output.join('\r\n')
	}

	getData() {
		return this.appState.data
	}

	getMinPoint() {
		return this.appState.data[0]
	}

	getMaxPoint() {
		return this.appState.data[this.appState.data.length - 1]
	}

	render() {
		return <>
			<Paper elevation={1} style={{ padding: 10 }}>
				<ImportButton dataManager={this} />
				<ExportButton dataManager={this} />
			</Paper>
		</>
	}
}	

export type { DataPoint }