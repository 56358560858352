import React from 'react'
import { Button } from '@mui/material'
import DataManager from '../DataManager'

interface ExportButtonProps {
	dataManager: DataManager
}

export default class ExportButton extends React.Component<ExportButtonProps> {
	constructor(props: ExportButtonProps) {
		super(props)
	}
	
	async handleClick() {
		const data = this.props.dataManager.exportData()

		const fileHandle = await window.showSaveFilePicker({
			types: [{
				description: 'CSV',
				accept: {
					'text/csv': ['.csv'],
				},
			}],
		})

		const fileStream = await fileHandle.createWritable()
		await fileStream.write(new Blob([ data ], { type: 'text/csv' }))
		await fileStream.close()
	}

	render() {
		return (
			<Button variant="contained" style={{ marginLeft: 20 }} onClick={this.handleClick.bind(this)}>Экспортировать</Button>
		)
	}
}	