import React from 'react'
import InputDataDisplay from './InputDataDisplay'
import KineticRelationship from './KineticRelationship'
import KineticCoefficient from './KineticCoefficient'
import { Paper } from '@mui/material'
import DataManager from '../DataManager'
import FunctionPicker from './FunctionPicker'

interface SettingsProps {
	dataManager: DataManager,
	onStepChange: () => void,
}

export default class Settings extends React.Component<SettingsProps> {
	state = {
		relationship: 1,
		coefficient: 0.25,
		stepSize: 1
	}

	constructor(props: SettingsProps) {
		super(props)
	} 

	handleRelationshipChange(value: number) {
		this.setState({
			relationship: value
		})
	}

	handleCoefficientChange(value: number) {
		this.setState({
			coefficient: value
		})
	}

	handleStepSizeChange(value: number) {
		this.setState({
			stepSize: value
		}, () => {
			this.props.onStepChange()
		})
	}

	getStepSize() {
		return this.state.stepSize
	}

	handleFunctionClick(fn: (x: number) => number) {
		this.props.dataManager.importFunction(fn)
	}

	render() {
		return (<>
			<td>
				<div className="td">
					{ this.props.dataManager.render() }
					<br/>
					<Paper elevation={1} style={{ padding: 10, height: 167 }}>
						<KineticRelationship value={this.state.relationship}  onChange={this.handleRelationshipChange.bind(this)}/>
						<br/>
						<KineticCoefficient value={this.state.coefficient} onChange={this.handleCoefficientChange.bind(this)} />
					</Paper>
				</div>
			</td>
			<td style={{ width: 100 }}>
				<div className="td">
					<FunctionPicker onClick={this.handleFunctionClick.bind(this)} />
				</div>
			</td>
		</>)
	}
}	