import React from 'react'
import MainState from './state/MainState'

import './App.css'

export default class App extends React.Component {
	componentDidMount() {
		document.title = "Graph Balance v1.0"

		window.addEventListener('resize', () => {
			this.forceUpdate()
		}, true)
	}

	render() {
		return (
			<div className="app">
				<MainState />
			</div>
		)
	}
}
