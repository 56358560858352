import React from 'react'
import { Slider, TextField } from '@mui/material'

const marks = [
	{ value: 0.25, label: '0,25' },
	{ value: 0.5, label: '0,5' },
	{ value: 0.75, label: '0,75' },
	{ value: 1, label: '1' },
]

interface KineticCoefficientProps {
	onChange: (value: number) => void,
	value: number
}

export default class KineticCoefficient extends React.Component<KineticCoefficientProps> {
	state = {
		value: 0.25
	}

	constructor(props: KineticCoefficientProps) {
		super(props)

		this.setState({
			value: this.props.value
		})
	}

	onChange(e: any) {
		this.setState({
			value: Math.min(1, Math.max(0, e.target.value))	
		}, () => {
			this.props.onChange(this.state.value)
		})
	}

	onSliderChange(e: Event, value: number | number[]) {
		this.setState({ 
			value: value
		}, () => {
			this.props.onChange(this.state.value)
		})
	}
	
	render() {
		return (<div style={{ minWidth: 400, marginTop: 10 }}>
			<TextField 
				value={this.state.value} 
				size="small" 
				label="Кинетический коэфицент" 
				type="number"
				onChange={this.onChange.bind(this)}
			/>

			<Slider
				value={this.state.value}
				valueLabelDisplay="auto"
				step={0.25 / 2}
				min={0}
				max={1}
				size="small"
				style={{ width: 'calc(100% - 260px)', marginLeft: '20px' }}
        marks={marks}
				onChange={this.onSliderChange.bind(this)}
			/>
		</div>)
	}
}	