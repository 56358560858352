import React from 'react'
import { TextField, Paper } from '@mui/material'
import './Components.css'
import DataManager from '../DataManager'

interface InputDataDisplayProps {
	dataManager: DataManager,
	stepSize: number,
	onChange: (value: number) => void
}

export default class InputDataDisplay extends React.Component<InputDataDisplayProps> {
	handleChange(who: string, e: React.ChangeEvent<HTMLInputElement>) {
		const textValue = e.target.value
		let value = 0

		if (textValue.length != 0) {
			try {
				value = parseFloat(textValue)
			} catch (e: any) {
				console.error(e)
			}
		}

		if (who == 'step') {
			return this.props.onChange(Math.max(1, value))
		}

		const manager = this.props.dataManager
		const min = manager.getMinPoint()
		const max = manager.getMaxPoint()
		const data = manager.getData()
		
		data.splice(0, 1)
		data.splice(data.length - 1, 1)

		switch (who) {
			case 'minX': min.x = Math.max(0, value); break
			case 'maxX': max.x = value; break
			case 'minY': min.y = value; break
			case 'maxY': max.y = value; break
		}

		while (true) {
			const length = max.x - min.x

			if (length > data.length) {
				if (who == 'minX') {
					data.unshift({ x: 0, y: data[0].x })
				} else {
					data.push({ x: data.length, y: data[data.length - 1].y })
				}
			} else if (length < data.length) {
				if (who == 'minX') {
					data.splice(0, 1)
				} else {
					data.splice(data.length - 1, 1)
				}
			} else {
				break
			}
		}

		data.unshift(min)
		data.push(max)

		manager.importData(data, true)
	}

	render() {
		const manager = this.props.dataManager
		const min = manager.getMinPoint()
		const max = manager.getMaxPoint()
		const data = manager.getData()

		// length - 2 takes into the account that min and max points are stored on the data array
		return (<Paper elevation={1} style={{ padding: 10 }}>
			<TextField className="inputData" size="small" label="Xmin" type="number" value={min.x} onChange={this.handleChange.bind(this, 'minX')} /><br/>
			<TextField className="inputData" size="small" label="Xmax" type="number" value={max.x} onChange={this.handleChange.bind(this, 'maxX')}  /><br/>
			<TextField className="inputData" size="small" label="Ymin" type="number" value={min.y} onChange={this.handleChange.bind(this, 'minY')}  /><br/>
			<TextField className="inputData" size="small" label="Ymax" type="number" value={max.y} onChange={this.handleChange.bind(this, 'maxY')}  /><br/>
			<TextField className="inputData" size="small" label="Step" type="number" value={this.props.stepSize} onChange={this.handleChange.bind(this, 'step')}  />
		</Paper>)
	}
}