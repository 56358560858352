import React from 'react'
import { Button } from '@mui/material'
import DataManager from '../DataManager'

interface ImportButtonProps {
	dataManager: DataManager
}

function beginsWithFloat(value: string) {
  return !isNaN(parseFloat(value))
}

export default class ImportButton extends React.Component<ImportButtonProps> {
	constructor(props: ImportButtonProps) {
		super(props)
	}
	
	importData(text: string) {
		let lines = text.replaceAll('\r', '').split('\n')
		let data = []

		for (let line of lines) {
			let parts = line.split(/;|,/)

			if (beginsWithFloat(parts[0]) && beginsWithFloat(parts[1])) {
				data.push({
					x: parseFloat(parts[0]),	
					y: parseFloat(parts[1])
				})
			}
		}

		if (data[0].x > data[data.length - 1].x) {
			console.log('Reversing data')
			data.reverse()
		}

		this.props.dataManager.importData(data, true)
	}

	async handleClick() {
		let handles = await window.showOpenFilePicker({
			types: [
				{
					description: 'Data',
					accept: {
						'text/*': ['.csv', '.txt']
					}
				},
			],

			excludeAcceptAllOption: true,
			multiple: false
		})

		const file = await handles[0].getFile()
		this.importData(await file.text())
	}

	render() {
		return (
			<Button variant="contained" onClick={this.handleClick.bind(this)}>
				Импортировать
			</Button>
		)
	}
}	